body {
  margin: 0;
}
.react-datepicker-popper {
  z-index: 100 !important;
}
.react-datepicker__close-icon {
  position: absolute;
  left: 225px;
  width: 25px;
  cursor: pointer;
}
#search-box-placeholder::placeholder {
  color: #808ca3;
  opacity: 1;
}

::placeholder {
  color: #808ca3;
}
